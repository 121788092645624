import React from 'react';
import PropTypes from 'prop-types';
import { PuzzelProvider } from './src/template/components/DesktopTools/PuzzelContext';

const prodPuzzelIds = {
  sampension: 'b065bb6c-9c03-4f6a-8b78-4ee21413c908',
  firma: 'b065bb6c-9c03-4f6a-8b78-4ee21413c908',
  isp: '94b01c06-59a4-491d-953e-e5fb9e01e252',
  ap: '35bd74d3-112e-4443-8b5c-aa207175ea00',
  pjd: 'be3e12fb-7e93-4074-950c-fcfad4327a80',
};

// for now all company groups share the same Id
const testPuzzleId = '3f9180cd-57da-4cb7-80c9-5664dd8c6249';

const testPuzzleIds = {
  sampension: testPuzzleId,
  firma: testPuzzleId,
  isp: testPuzzleId,
  ap: testPuzzleId,
  pjd: testPuzzleId,
};

const { host } = global.window.location;
const isTest = host.indexOf('web-test') === 0;
const isDev = host.indexOf('web-dev') === 0 || host.indexOf('localhost') === 0;

const puzzelIds = (isTest || isDev) ? testPuzzleIds : prodPuzzelIds;
const puzzelId = puzzelIds[process.env.GATSBY_THEME || 'sampension'];

export const wrapRootElement = ({ element }) => (
  <PuzzelProvider id={puzzelId}>{element}</PuzzelProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
